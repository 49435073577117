import React, { FC } from 'react';
import { GenericLayout } from '../layouts/generic';
import { Helmet } from 'react-helmet';
import { Title } from '@nn-virtual-pen/education/ui';
import { TranslationKey, useTranslate } from '@nn-virtual-pen/education/data-access';
import * as Styled from '../components/not-found/not-found.styled';

export const ExceedLimit: FC = () => {
  const { t } = useTranslate();

  return (
    <GenericLayout>
      <Helmet title="Exceeded limit" />
      <Styled.Wrapper>
        <Title>{t(TranslationKey.exceed_limit)}</Title>
      </Styled.Wrapper>
    </GenericLayout>
  );
};

export default ExceedLimit;
